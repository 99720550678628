import * as tslib_1 from "tslib";
import { JsonData } from "./common/json-data.model";
import { JsonObject, JsonProperty } from "json2typescript";
let Operation = 
/**
 * export class
 * Operation
 * extiende de JsonData
 */
class Operation extends JsonData {
    constructor() {
        super(...arguments);
        /**
         * variables
         */
        this.cardNumber = undefined;
        this.commerce = undefined;
        this.amount = undefined;
        this.currency = undefined;
        this.cardName = undefined;
        this.status = undefined;
    }
};
tslib_1.__decorate([
    JsonProperty("cardNumber", String, true)
], Operation.prototype, "cardNumber", void 0);
tslib_1.__decorate([
    JsonProperty("commerce", String, true)
], Operation.prototype, "commerce", void 0);
tslib_1.__decorate([
    JsonProperty("amount", Number, true)
], Operation.prototype, "amount", void 0);
tslib_1.__decorate([
    JsonProperty("currency", String, true)
], Operation.prototype, "currency", void 0);
tslib_1.__decorate([
    JsonProperty("cardName", String, true)
], Operation.prototype, "cardName", void 0);
tslib_1.__decorate([
    JsonProperty("status", String, true)
], Operation.prototype, "status", void 0);
Operation = tslib_1.__decorate([
    JsonObject("Operation")
    /**
     * export class
     * Operation
     * extiende de JsonData
     */
], Operation);
export { Operation };
/**
 * export namespace
 * Operation
 */
// tslint:disable-next-line:no-namespace
(function (Operation) {
    Operation.statusTypes = {
        INICIADA: "I",
        CADUCADA: "C",
        SI: "S",
        NO: "N",
    };
})(Operation || (Operation = {}));
