import { ConfigService } from "@ng-darwin/config";
import { Injectable } from "@angular/core";
import { ApiCallUrl } from "./apiCall.interface";

@Injectable({
  providedIn: "root",
})
/**
 * export class
 * ApiService
 */
export class ApiService {
  /**
   * constructor
   */
  constructor(private configService: ConfigService) {}
  /**
   * llama a la url
   */
  getApiUrl(apiCallUrl: ApiCallUrl): string {
    for (const param in apiCallUrl.params) {
      if (apiCallUrl.params.hasOwnProperty(param)) {
        const keyFormatted = this._getKeyFormatted(
          apiCallUrl.params[param].key
        );
        apiCallUrl.path = apiCallUrl.path.replace(
          keyFormatted,
          apiCallUrl.params[param].value
        );
      }
    }

    const url = this.configService.config.app.endpointSCA + apiCallUrl.path;

    return url;
  }

  /**
   * asigna el valor a la url
   */
  getApiInstance(value: ApiCallUrl): ApiCallUrl {
    return Object.assign({}, value) as ApiCallUrl;
  }

  /**
   * formatea la key
   */
  private _getKeyFormatted(key: string): string {
    return "{" + key + "}";
  }
}
