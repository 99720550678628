/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip-language.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tooltip-language.component";
import * as i4 from "../../app.constants";
var styles_TooltipLanguageComponent = [i0.styles];
var RenderType_TooltipLanguageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipLanguageComponent, data: {} });
export { RenderType_TooltipLanguageComponent as RenderType_TooltipLanguageComponent };
export function View_TooltipLanguageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "body-sabana"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "triangle-tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "row-language"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "square-language"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.selectLanguage(_co.appConstants.LANGUAGE.ES) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "selected": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "square-language"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.selectLanguage(_co.appConstants.LANGUAGE.EU) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "selected": 0 }), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "square-language"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.selectLanguage(_co.appConstants.LANGUAGE.EN) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "selected": 0 }), (_l()(), i1.ɵted(17, null, [" ", " "])), (_l()(), i1.ɵeld(18, 0, null, null, 10, "div", [["class", "row-language"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "square-language"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.selectLanguage(_co.appConstants.LANGUAGE.CA) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "selected": 0 }), (_l()(), i1.ɵted(23, null, [" ", " "])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "square-language"]], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.selectLanguage(_co.appConstants.LANGUAGE.GL) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(26, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(27, { "selected": 0 }), (_l()(), i1.ɵted(28, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "square-language"; var currVal_1 = _ck(_v, 6, 0, _co.isSelected(_co.appConstants.LANGUAGE.ES)); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = "square-language"; var currVal_4 = _ck(_v, 11, 0, _co.isSelected(_co.appConstants.LANGUAGE.EU)); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = "square-language"; var currVal_7 = _ck(_v, 16, 0, _co.isSelected(_co.appConstants.LANGUAGE.EN)); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_9 = "square-language"; var currVal_10 = _ck(_v, 22, 0, _co.isSelected(_co.appConstants.LANGUAGE.CA)); _ck(_v, 21, 0, currVal_9, currVal_10); var currVal_12 = "square-language"; var currVal_13 = _ck(_v, 27, 0, _co.isSelected(_co.appConstants.LANGUAGE.GL)); _ck(_v, 26, 0, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.appConstants.LANGUAGE.ES; _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.appConstants.LANGUAGE.EU; _ck(_v, 12, 0, currVal_5); var currVal_8 = _co.appConstants.LANGUAGE.EN; _ck(_v, 17, 0, currVal_8); var currVal_11 = _co.appConstants.LANGUAGE.CA; _ck(_v, 23, 0, currVal_11); var currVal_14 = _co.appConstants.LANGUAGE.GL; _ck(_v, 28, 0, currVal_14); }); }
export function View_TooltipLanguageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sca-tooltip-language", [], null, null, null, View_TooltipLanguageComponent_0, RenderType_TooltipLanguageComponent)), i1.ɵdid(1, 49152, null, 0, i3.TooltipLanguageComponent, [i4.AppConstants], null, null)], null, null); }
var TooltipLanguageComponentNgFactory = i1.ɵccf("sca-tooltip-language", i3.TooltipLanguageComponent, View_TooltipLanguageComponent_Host_0, { languageSelected: "languageSelected" }, { language: "language" }, []);
export { TooltipLanguageComponentNgFactory as TooltipLanguageComponentNgFactory };
