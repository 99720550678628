import { TealiumUtagService } from "./../../core/services/utag.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "@ng-darwin/config";
import { Autenticate } from "./../../core/models/autenticate.model";
import { AppConstants } from "./../../app.constants";
import { Operation } from "./../../core/models/operation.model";
import { SCAService } from "./../../core/services/sca.service";
import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
} from "@angular/core";

import { takeWhile } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { JSEncrypt } from "jsencrypt";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "src/app/core/services/error.service";
import { ErrorResponse, ErrorResponseBody, isFunctionalError, isTechnicalError } from "src/app/core/models/error.model";
@Component({
  selector: "sca-autentication",
  templateUrl: "./autentication.component.html",
  styleUrls: ["./autentication.component.scss"],
})
/**
 * export class
 * AutenticationComponent
 */
export class AutenticationComponent implements OnInit, OnDestroy {
  // varibles
  @ViewChild("inputPassword", { static: false })
  inputPassword!: ElementRef;
  sortUrl!: string;
  password!: string;
  operation: Operation = new Operation();
  placeholder!: string;
  active = false;
  private alive = true;
  // variables para el encriptado
  PIN!: number;
  publicKey!: string;
  disabledButton = false;
  /**
   * Constructor
   *
   */
  constructor(
    private readonly scaService: SCAService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly configService: ConfigService,
    private readonly appConstants: AppConstants,
    private readonly translate: TranslateService,
    private readonly tealium: TealiumUtagService,
    private readonly errorService: ErrorService
  ) {
    this.publicKey = this.configService.config.app.publicKey as string;
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.sortUrl = params.sortUrl;
      this.getOperation(this.sortUrl);
    });
  }
  /**
   * envia el estado
   * de la operacion
   */
  getOperation(urlSort: string) {
    this.scaService
      .getOperation(urlSort)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (operation: Operation) => {
          this.operation = operation;
          if (this.operation.status === Operation.statusTypes.CADUCADA) {
            this.router.navigate([
              this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_TIMEOUT.path,
            ]);
          } else {
            setTimeout(() => {
              this.tealium.track(this.appConstants.TEALIUM.EVENT.EV_SHOW_VIEW, {
                page_name: this.appConstants.UTAG_DATA.page_name,
                page_title: this.appConstants.UTAG_DATA.page_title,
                page_type: this.appConstants.UTAG_DATA.page_type,
              });
            }, 1000);
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.router.navigate([
              this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_PAGE_NOT_EXIST
                .path,
            ]);
          } else if (error.status === 400) {

            const errorResponse: ErrorResponseBody = error/*HttpErrorResponse*/.error/*ErrorResponse*/.error/*ErrorResponseBody*/ || {}; 
            this.errorService.add(errorResponse);
            this.router.navigate([this.getPathError(errorResponse)]);
          } else {
            this.router.navigate([
              this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_NOT_AUTENTICATED
                .path,
            ]);
          }
        }
      );
  }
  /**
   * metodo que encripta y envia
   * la contraseña encriptada al back
   * con la libreria JSEncrypt
   */
  sendAuthentication() {
    this.active = true;
    // encriptado
    const jsenc = new JSEncrypt(); // Instancia la librería.
    jsenc.setPublicKey(this.publicKey); // Setea la clave pública obtenida del config
    const encryptedPIN = jsenc.encrypt(this.password); // Cifra el PIN indicado
    if (encryptedPIN) {
      this.scaService
        .sendAuthentication(encryptedPIN, this.sortUrl)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (autenticate) => {
            this.disabledButton = false;
            const status: string = autenticate.estado ? autenticate.estado : "";
            this.redirectModal(status, autenticate);
          },
          () => {
            this.router.navigate([
              this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_PAGE_NOT_EXIST
                .path,
            ]);
          }
        );
    }
    this.placeholder = this.translate.instant("AUTENTICATION.PASSWORD_ERROR");
  }
  /**
   * muestra el teclado
   */
  showKeyboard() {
    this.inputPassword.nativeElement.focus();
  }

  /**
   * Valida la entrada de solo numeros en el pasword
   */
  validarNumero(event: any) {
    const pattern = /^([0-9])*$/;
    let inputChar = "";
    if (event.type === "paste") {
      inputChar = event.clipboardData.getData("Text");
    } else {
      inputChar = String.fromCharCode(event.charCode);
    }

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  /**
   * metodo que sirve
   * para recordar la contraseña/pin
   */
  goToRememberPass() {
    this.trackAction(1);
    window.location.href = String(
      this.configService.config.app.endpointRememberPass
    );
  }
  /**
   * metodo que sirve para
   * ver la informacion de
   * seguridad
   */
  goToSecurityInformation() {
    this.trackAction(2);
    window.location.href = String(
      this.configService.config.app.endpointSecurityInformation
    );
  }
  /**
   * metodo para enviar al servicio tealium los
   * links ejecutados en la pagina
   */
  trackAction(param: number) {
    if (param === 1) {
      this.tealium.track(this.appConstants.TEALIUM.EVENT.EV_SEND_ACTION, {
        action: this.appConstants.TEALIUM.ACTION.CLICK,
        category: this.appConstants.TEALIUM.CATEGORY,
        label: this.appConstants.TEALIUM.LABEL.REMEMBER_PASS,
      });
    } else {
      this.tealium.track(this.appConstants.TEALIUM.EVENT.EV_SEND_ACTION, {
        action: this.appConstants.TEALIUM.ACTION.CLICK,
        category: this.appConstants.TEALIUM.CATEGORY,
        label: this.appConstants.TEALIUM.LABEL.HELP,
      });
    }
  }

  /**
   * ngOnDestroy
   * acaba el proceso
   */
  ngOnDestroy() {
    this.alive = false;
  }
  /**
   * Muestra las distintas pantalla
   * de error que nos pueden
   * saltar al usarla
   *
   */
  private redirectModal(status: string, autenticate:object) {
    switch (status) {
      case Autenticate.statusTypes.OK: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_SUCCESS.path,
        ]);
        break;
      }
      case Autenticate.statusTypes.KO1: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_NEW_TRY.path,
        ]);
        break;
      }
      case Autenticate.statusTypes.KO2: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_BLOCKED.path,
        ]);
        break;
      }
      case Autenticate.statusTypes.KO3: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_TIMEOUT.path,
        ]);
        break;
      }
      case Autenticate.statusTypes.KO4: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_BLOCKED.path,
        ]);
        break;
      }
      case Autenticate.statusTypes.ERRCAD02: {
        this.errorService.add(autenticate),
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_TECHNICAL_CUSTOM.path,
        ]);
        break;
      }
      default: {
        this.router.navigate([
          this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_NOT_AUTENTICATED.path,
        ]);
        break;
      }
    }
  }

  private getPathError(errorBody: ErrorResponseBody): string {
    let route: { path: string };
    if (isFunctionalError(errorBody.estado)) {
      route = this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_FUNCTIONAL_ERROR;
    } else if (isTechnicalError(errorBody.estado)) {
      route = this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_TECHNICAL_ERROR;
    } else {
      route = this.appConstants.ROUTES_DIRECTORY.MODAL_ERROR_NOT_AUTENTICATED;
    }
    return route.path;
  }
}
