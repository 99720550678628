import { TealiumUtagService } from "./../../core/services/utag.service";
import { ConfigService } from "@ng-darwin/config";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from 'src/app/app.constants';
import { ErrorService } from "src/app/core/services/error.service";
import { ErrorModal } from "src/app/core/models/error.model";

@Component({
  selector: "sca-modal-error",
  templateUrl: "./modal-error.component.html",
  styleUrls: ["./modal-error.component.scss"],
})
/**
 * export class
 * ModalErrorComponent
 */
export class ModalErrorComponent {
  // variables
  public param?: number;
  public sortUrl?: string;
  public error: ErrorModal;

  /**
   * constructor
   */
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly configService: ConfigService,
    private readonly tealium: TealiumUtagService,
    private readonly appConstants: AppConstants,
    private readonly errorService: ErrorService
  ) {
    this.error = { mainText: "", code: "", secondaryText: "" }
  }
  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.errorService.$error.subscribe(error => this.error = error);

    this.activatedRoute.params.subscribe((params) => {
      this.param = Number(params.param);
      this.trackAction(this.param);
    });
  }

  /**
   * metodo para ejecutar los eventos de tealium
   */
  trackAction(param: number) {
    let action: string;
    let label: string;

    switch (param) {
      case 1:
        setTimeout(() => {
          this.tealium.track(this.appConstants.TEALIUM.EVENT.EV_SHOW_VIEW,
            {
              page_name: this.appConstants.TEALIUM.SUCCESS.page_name,
              page_title: this.appConstants.TEALIUM.SUCCESS.page_title,
              page_type: this.appConstants.TEALIUM.SUCCESS.page_type
            });
        }, 1000);
        break;
      case 2:
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.NEW_TRY
        break;
      case 3:
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.EXPIRED
        break;
      case 4:
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.BLOCKED_PASS
        break;
      case 5:
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.SERVER_ERROR
        break;
      case 6:
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.NO_EXIST
        break;
      case 7: // Functional Errors
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.getLabelForFunctionalErrors(this.error.code ? this.error.code : "")
        break;
      case 8: // Technical Errors
        action = this.appConstants.TEALIUM.ACTION.ERROR_LOGIN;
        label = this.appConstants.TEALIUM.LABEL.SERVER_ERROR
        break;
    }

    if (param !== 1 && param) {
      setTimeout(() => {
        this.tealium.track(this.appConstants.TEALIUM.EVENT.EV_SHOW_VIEW, {
          page_name: this.appConstants.TEALIUM.ERROR.page_name,
          page_title: this.appConstants.TEALIUM.ERROR.page_title,
          page_type: this.appConstants.TEALIUM.ERROR.page_type,
        });
      }, 1000);
      setTimeout(() => {
        this.tealium.track(
          this.appConstants.TEALIUM.EVENT.EV_SEND_ACTION,
          { action: action, category: this.appConstants.TEALIUM.CATEGORY, label: label });
      }, 1500);
    }
  }
  /**
   * muestra un icono u otro
   * de advertencia
   */
  showIconAdvertise(): boolean {
    return (
      this.param === 2 ||
      this.param === 3 ||
      this.param === 4 ||
      this.param === 5
    );
  }

  /**
   * muestra el texto principal
   * segun la opcion
   */
  showMainText(): boolean {
    return this.param === 2 || this.param === 3 || this.param === 4;
  }
  /**
   *  is functional error
   * @returns boolean
   */
  isFunctionalError() {
    return this.param === 7;
  }
  /**
   * is Technical error
   * @returns boolean
   */
  isTechnicalError() {
    return this.param === 8;
  }

  /**
  * get label for functional errors
  * @param status string
  * @returns string
  */
  getLabelForFunctionalErrors(status: string): string {

    if (this.isNewTryCode(status)) {
      return this.appConstants.TEALIUM.LABEL.NEW_TRY
    }

    if (this.isBlockedPassCode(status)) {
      return this.appConstants.TEALIUM.LABEL.BLOCKED_PASS
    }

    if (this.isExpiredErrorCode(status)) {
      return this.appConstants.TEALIUM.LABEL.EXPIRED;
    }
    if (this.isTimeExpiredErrorCode(status)) {
      return this.appConstants.TEALIUM.LABEL.EXPIRED;
    }

    return this.appConstants.TEALIUM.LABEL.SERVER_ERROR

  }

  /**
* is new try code
* @param status string
* @returns boolean
*/
  isNewTryCode(status: string): boolean {
    return ["50201001"].includes(status.trim());
  }
  /**
   * si blocked pass code
   * @param status string
   * @returns boolean
   */
  isBlockedPassCode(status: string): boolean {
    return ["20201003", "20201002", "50201014", "50201023"].includes(status.trim());
  }
  /**
   * is Expired Error Code
   * @param status
   * @returns
   */
  isExpiredErrorCode(status: string): boolean {
    return ["ERRCAD01"].includes(status.trim());
  }

  /**
   * is time expired Error code
   * @param status 
   * @returns 
   */
  isTimeExpiredErrorCode(status: string): boolean {
    return ["ERBDCD02"].includes(status.trim());
  }

  /**
   * cierra el modal
   */
  closeModal() {
    window.location.href = String(
      this.configService.config.app.endpointRedirectClose
    );
  }
}
