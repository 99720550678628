import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { RoutesRecognized } from "@angular/router";
/**
 * export class
 * AppComponent
 */
export class AppComponent {
    /**
     * constructor
     */
    constructor(translate, router, appConstants, tealium, configService) {
        this.translate = translate;
        this.router = router;
        this.appConstants = appConstants;
        this.tealium = tealium;
        this.configService = configService;
        /**
         * variables
         */
        this.showTooltip = false;
        this.languageSelected = this.appConstants.LANGUAGE.ES;
        this.showComboLanguage = false;
        this.tealium.setConfig({
            account: String(this.configService.config.app.tealium_account),
            profile: String(this.configService.config.app.tealium_profile),
            environment: String(this.configService.config.app.tealium_environment),
        });
        translate.addLangs([
            appConstants.LANGUAGE.EN.toLocaleLowerCase(),
            appConstants.LANGUAGE.ES.toLocaleLowerCase(),
            appConstants.LANGUAGE.FR.toLocaleLowerCase(),
            appConstants.LANGUAGE.EU.toLocaleLowerCase(),
            appConstants.LANGUAGE.GL.toLocaleLowerCase(),
        ]);
        translate.use(appConstants.LANGUAGE.ES.toLocaleLowerCase());
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.checkShowCombo();
        });
    }
    checkShowCombo() {
        this.router.events.forEach((event) => {
            if (event instanceof RoutesRecognized) {
                event.urlAfterRedirects.indexOf(this.appConstants.ROUTES_DIRECTORY.AUTENTICATION.path) > -1
                    ? (this.showComboLanguage = true)
                    : (this.showComboLanguage = false);
            }
        });
    }
    showTooltipLanguage() {
        this.showTooltip = !this.showTooltip;
    }
    setLanguage(languageSelected) {
        this.languageSelected = languageSelected;
        this.translate.use(this.languageSelected.toLocaleLowerCase());
    }
}
