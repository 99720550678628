import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
@Injectable()
export class TealiumUtagService {
  scriptSrc = '';

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(public appConstants: AppConstants) {
    (window as any).utag_cfg_ovrd = { noview : true };
    (window as any).utag_data = this.appConstants.UTAG_DATA;
  }

  // Generic script loader with callback
  getScript(src: string) {
    const d = document;

    if (typeof src === 'undefined') {
      return;
    }

    const s: any = d.createElement('script'); s.language = 'javascript'; s.type = 'text/javascript'; s.async = 1; s.charset = 'utf-8'; s.src = src;
    // @ts-ignore (Old IE Support)
    if ( d.addEventListener ) {
      s.addEventListener('load', () => {}, false);
    } else {
      // old IE support
      s.onreadystatechange = function(){
        if (this.readyState === 'complete' || this.readyState === 'loaded'){this.onreadystatechange = null}
      };
    }
    const t: any = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file
  setConfig(config: {account: string, profile: string, environment: string}) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined ) {
      this.getScript(this.scriptSrc = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js');
    }
  }

  // Data layer is optional set of key/value pairs
  track(event: string, data: any) {
    if(event === 'evShowView') {
      (window as any).utag_data.page_name = data.page_name;
      (window as any).utag_data.page_title = data.page_title;
      (window as any).utag_data.page_type = data.page_type;
    }
    (window as any).jQuery('body').trigger(event, data);
  }

}
