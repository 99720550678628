import * as tslib_1 from "tslib";
import { JsonData } from "./common/json-data.model";
import { JsonObject, JsonProperty } from "json2typescript";
let Autenticate = 
/**
 * export class Autenticate
 */
class Autenticate extends JsonData {
    constructor() {
        super(...arguments);
        this.estado = undefined;
        this.descError = undefined;
    }
};
tslib_1.__decorate([
    JsonProperty("estado", String, true)
], Autenticate.prototype, "estado", void 0);
tslib_1.__decorate([
    JsonProperty("descError", Object, true)
], Autenticate.prototype, "descError", void 0);
Autenticate = tslib_1.__decorate([
    JsonObject("statusTypes")
    /**
     * export class Autenticate
     */
], Autenticate);
export { Autenticate };
/**
 * export namespace Autenticate
 */
// tslint:disable-next-line:no-namespace
(function (Autenticate) {
    Autenticate.statusTypes = {
        OK: "OK",
        KO1: "KO1",
        KO2: "KO2",
        KO3: "KO3",
        KO4: "KO4",
        KO5: "KO5",
        KO6: "KO6",
        KO7: "KO7",
        ERRCAD02: "ERRCAD02"
    };
})(Autenticate || (Autenticate = {}));
