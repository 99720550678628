<div class="greeting">
  <div class="hi">
    {{ "AUTENTICATION.GREETING" | translate }}
  </div>
  <div class="name">
    {{ operation.cardName }}
  </div>
</div>
<div class="text1">
  {{ "AUTENTICATION.TEXT1" | translate }}
</div>
<div class="resume">
  <div class="resume-in">
    <div class="text-resume">
      <div class="title">{{ "AUTENTICATION.IMPORT" | translate }}</div>
      <div class="import">
        {{
          operation.amount | currency: operation.currency:"symbol":"1.2-2":"es"
        }}
      </div>
    </div>
    <div class="dot-line"></div>
    <div class="text-resume">
      <div class="title">{{ "AUTENTICATION.MARKET" | translate }}</div>
      <div class="market">{{ operation.commerce }}</div>
    </div>
    <div class="dot-line"></div>
    <div class="text-resume">
      <div class="title">
        <span>{{ "AUTENTICATION.NUM_CARD" | translate }}</span>
      </div>
      <div class="num-card">
        {{ "AUTENTICATION.ASTERISKS" | translate }}{{ operation.cardNumber }}
      </div>
    </div>
  </div>
</div>
<div class="text2">
  {{ "AUTENTICATION.TEXT2" | translate }}
</div>
<form #authentication="ngForm">
  <div class="input-password">
    <input
      id="pass"
      #inputPassword
      type="password"
      name="password"
      maxlength="8"
      minlength="4"
      pattern="^[0-9]*$"
      [(ngModel)]="password"
      (keypress)="validarNumero($event)"
      (paste)="validarNumero($event)"
      [ngClass]="{ 'no-enable': !password && active }"
      aria-label="pass"
      placeholder="{{
        !password && active ? placeholder : ('AUTENTICATION.PASSWORD' | translate)
      }}"
      required
    />
    <div class="red" (click)="showKeyboard()">
      <div class="teclado"><i class="icon-keypad"></i></div>
    </div>
  </div>
  <div class="question">
    <div class="text_security" (click)="goToSecurityInformation()">
      {{ "AUTENTICATION.SECURITY_INFORMATION" | translate }}
    </div>
  </div>
  <button type="button" id="authenticate"
    class="button"
    [disabled]="disabledButton || authentication.invalid"
    (click)="disabledButton = true; sendAuthentication()">
    <span class="text">{{ "AUTENTICATION.BUTTON_NEXT" | translate }}</span>
  </button>
</form>

