import { BehaviorSubject } from 'rxjs';
import { ErrorModal, ErrorResponseBody } from '../models/error.model';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class ErrorService {
    constructor(translateService) {
        this.translateService = translateService;
        /**
         * Error
         */
        this.error = new BehaviorSubject({
            code: "",
            mainText: "",
            secondaryText: ""
        });
        /**
         * errorResponse
         */
        this.errorResponse = {};
        /**
         * $error
         */
        this.$error = this.error.asObservable();
        translateService.onLangChange.subscribe(() => {
            this.add(this.errorResponse);
        });
    }
    /**
     * add
     * @param errorResponse errorResponse
     */
    add(errorResponse) {
        this.errorResponse = errorResponse;
        const error = {
            code: errorResponse.estado,
            mainText: errorResponse.descError ? this.getTextByLang(errorResponse.descError, true, this.translateService.currentLang) : "",
            secondaryText: errorResponse.descError ? this.getTextByLang(errorResponse.descError, false, this.translateService.currentLang) : "",
        };
        this.error.next(error);
    }
    /**
     * get text by lang
     * @param descError descError
     * @param isMain is main text or not
     * @param lang lang
     * @returns string
     */
    getTextByLang(descError, isMain, lang = this.translateService.currentLang) {
        if (!descError) {
            return "";
        }
        const item = descError.find(item => item.language === lang);
        if (!item) {
            return "";
        }
        if (isMain) {
            return item.value.split(".")[0];
        }
        else {
            const aux = item.value.split(".");
            aux.shift();
            return aux.join(".");
        }
    }
}
ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.ɵɵinject(i1.TranslateService)); }, token: ErrorService, providedIn: "root" });
