import { JsonData } from "./common/json-data.model";
import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Operation")
/**
 * export class
 * Operation
 * extiende de JsonData
 */
export class Operation extends JsonData {
  /**
   * variables
   */
  @JsonProperty("cardNumber", String, true)
  cardNumber: string | undefined = undefined;

  @JsonProperty("commerce", String, true)
  commerce: string | undefined = undefined;

  @JsonProperty("amount", Number, true)
  amount: number | undefined = undefined;

  @JsonProperty("currency", String, true)
  currency: string | undefined = undefined;

  @JsonProperty("cardName", String, true)
  cardName: string | undefined = undefined;

  @JsonProperty("status", String, true)
  status: string | undefined = undefined;
}

/**
 * export namespace
 * Operation
 */
// tslint:disable-next-line:no-namespace
export namespace Operation {
  /**
   * constantes,
   * tipo de estatus
   */
  export type statusTypes = "I" | "C" | "S" | "N";

  export const statusTypes = {
    INICIADA: "I" as statusTypes,
    CADUCADA: "C" as statusTypes,
    SI: "S" as statusTypes,
    NO: "N" as statusTypes,
  };
}
