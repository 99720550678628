import * as i0 from "@angular/core";
import * as i1 from "@ng-darwin/config";
/**
 * export class
 * ApiService
 */
export class ApiService {
    /**
     * constructor
     */
    constructor(configService) {
        this.configService = configService;
    }
    /**
     * llama a la url
     */
    getApiUrl(apiCallUrl) {
        for (const param in apiCallUrl.params) {
            if (apiCallUrl.params.hasOwnProperty(param)) {
                const keyFormatted = this._getKeyFormatted(apiCallUrl.params[param].key);
                apiCallUrl.path = apiCallUrl.path.replace(keyFormatted, apiCallUrl.params[param].value);
            }
        }
        const url = this.configService.config.app.endpointSCA + apiCallUrl.path;
        return url;
    }
    /**
     * asigna el valor a la url
     */
    getApiInstance(value) {
        return Object.assign({}, value);
    }
    /**
     * formatea la key
     */
    _getKeyFormatted(key) {
        return "{" + key + "}";
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.ConfigService)); }, token: ApiService, providedIn: "root" });
