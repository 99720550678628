<div class="body-sabana">
    <div class="triangle-tooltip"></div>
    <div class="row-language">
        <div class="square-language" 
            [ngClass]="{'selected': isSelected(appConstants.LANGUAGE.ES)}" 
            (mousedown)="selectLanguage(appConstants.LANGUAGE.ES)">
            {{appConstants.LANGUAGE.ES}}
        </div>
        <div class="square-language" 
            [ngClass]="{'selected': isSelected(appConstants.LANGUAGE.EU)}" 
            (mousedown)="selectLanguage(appConstants.LANGUAGE.EU)">
            {{appConstants.LANGUAGE.EU}}
        </div>
        <div class="square-language" 
            [ngClass]="{'selected': isSelected(appConstants.LANGUAGE.EN)}" 
            (mousedown)="selectLanguage(appConstants.LANGUAGE.EN)">
            {{appConstants.LANGUAGE.EN}}
        </div>
    </div>
    <div class="row-language">
        <div class="square-language" 
            [ngClass]="{'selected': isSelected(appConstants.LANGUAGE.CA)}" 
            (mousedown)="selectLanguage(appConstants.LANGUAGE.CA)">
            {{appConstants.LANGUAGE.CA}}
        </div>
        <div class="square-language" 
            [ngClass]="{'selected': isSelected(appConstants.LANGUAGE.GL)}" 
            (mousedown)="selectLanguage(appConstants.LANGUAGE.GL)">
            {{appConstants.LANGUAGE.GL}}
        </div>
    </div>
</div>
