import { Autenticate } from "./../models/autenticate.model";
import { Operation } from "./../models/operation.model";
import { API_CALL_URL_SCA_MMPP } from "./../api/api.sca.constants";
import { ApiService } from "./../api/api.service";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * export class
 * SCAService
 */
export class SCAService {
  // variables
  private readonly sendOperationToAuth: BehaviorSubject<
    Operation
  > = new BehaviorSubject<Operation>(new Operation());
  private readonly getOperationInAuth$: Observable<
    Operation
  > = this.sendOperationToAuth.asObservable();
  /**
   * constructor
   */
  constructor(
    private readonly apiService: ApiService,
    public http: HttpClient
  ) { }
  /**
   * getOperation
   */
  getOperation(sortUrl: string): Observable<Operation> {
    const apiInstance = this.apiService.getApiInstance(
      API_CALL_URL_SCA_MMPP.GET_OPERATION
    );
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('X-ClientId', 'scatar');
    apiInstance.params.sortUrl.value = sortUrl;
    const url = this.apiService.getApiUrl(apiInstance);
    return this.http
      .get(url, { headers: headers })
      .pipe(map(this.extractOperation), catchError(this.handleError));
  }
  /**
   * sendAuthentication
   * envia la contraseña/pin
   */
  sendAuthentication(
    password: string,
    shortUrl: string
  ): Observable<Autenticate> {
    const apiInstance = this.apiService.getApiInstance(
      API_CALL_URL_SCA_MMPP.AUTHENTICATION
    );

    const body = {
      shortUrl,
      password,
    };
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('X-ClientId', 'scatar');
    const url = this.apiService.getApiUrl(apiInstance);
    return this.http
      .post(url, body, { headers: headers })
      .pipe(map(this.extractResponse), catchError(this.handleError));
  }
  /**
   * sendOperationToAuthentication
   * envia los datos para autenticarlos
   */
  sendOperationToAuthentication(operation: Operation) {
    this.sendOperationToAuth.next(operation);
  }
  /**
   * getOperationToAuthentication
   * recoge los datos de la autenticacion
   */
  getOperationToAuthentication(): Observable<Operation> {
    return this.getOperationInAuth$;
  }
  /**
   * extrae los datos de la oepracion
   */
  private extractOperation(res: Response | any) {
    return Operation.buildJson(res, Operation);
  }
  /**
   * obtiene respuesta de autenticacion
   */
  private extractResponse(res: Response | any) {
    return Autenticate.buildJson(res, Autenticate);
  }
  /**
   * Devuelve una excepcion, error
   */
  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}

