import { TealiumUtagService } from "./core/services/utag.service";
import { AppConstants } from "./app.constants";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { ConfigModule } from "@ng-darwin/config";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { AutenticationComponent } from "./components/autentication/autentication.component";
import { ModalErrorComponent } from "./components/modal-error/modal-error.component";
import { TooltipLanguageComponent } from "./components/tooltip-language/tooltip-language.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";

import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
registerLocaleData(localeEs);
/**
 * export function
 * translateLoaderFactory
 * traduce al idioma local
 */
export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
/**
 * NgModule
 */
@NgModule({
  /**
   * declaraciones
   */
  declarations: [
    AppComponent,
    AutenticationComponent,
    ModalErrorComponent,
    TooltipLanguageComponent,
  ],
  /**
   * imports
   */
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    /**
     * configModule
     */
    ConfigModule.forRoot({
      logEvent: {
        level: environment.configLogLevel,
        handler(ev) {
          console.log("Log event from Config module:", ev);
        },
      },
      /**
       * errores de evento
       */
      errorEvent: {
        handler(ev) {
          console.error("Error event from Config module:", ev);
        },
      },
      /**
       * configuracion del evento
       */
      configLoadedEvent: {
        handler(ev) {
          console.log("ConfigLoaded event from Config module:", ev);
        },
      },
    }),
  ],
  /**
   * providers
   */
  providers: [
    { provide: AppConstants, useClass: AppConstants },
    TealiumUtagService,
  ],
  /**
   *   bootstrap
   */
  bootstrap: [AppComponent],
})
/**
 * export class
 * AppModule
 */
export class AppModule {}
