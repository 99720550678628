import { AppConstants } from "./../../app.constants";
import {
  Component,
  Output,
  EventEmitter,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";

@Component({
  selector: "sca-tooltip-language",
  templateUrl: "./tooltip-language.component.html",
  styleUrls: ["./tooltip-language.component.scss"],
})
/**
 * export class
 * TooltipLanguageComponent
 */
export class TooltipLanguageComponent {
  // variables
  @Input() languageSelected: string = this.appConstants.LANGUAGE.ES;
  @Output() language: EventEmitter<string> = new EventEmitter();
  /**
   * constructor
   */
  constructor(public appConstants: AppConstants) {}
  /**
   * selecciona el lenguaje
   */
  selectLanguage(language: string) {
    this.language.emit(language);
  }
  /**
   * muestra el lenguaje
   * anteriomente seleccionado
   */
  isSelected(languageSelected: string): boolean {
    return this.languageSelected === languageSelected;
  }
}
