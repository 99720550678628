import { JsonProperty } from "json2typescript";
import { JsonData } from "./common/json-data.model";

/**
 * Error Modal type
 */
export type ErrorModal = {
  code?: string;
  mainText: string;
  secondaryText: string;
}

/**
 *  Error Response
 *  
 */
export class ErrorResponse extends JsonData {
  @JsonProperty("error", Object, true)
  error?: ErrorResponseBody = undefined;

}
/**
 * Error Response Body
 */
export class ErrorResponseBody extends JsonData {

  @JsonProperty('estado', String, true)
  estado?: string = undefined;

  @JsonProperty('descError', Array, true)
  descError?: { language: string, value: string }[] | null = undefined
}


/**
   * Functional Errors values
   */
const functionalErrors =
  [
    "20201003",
    "50201001",
    "40201008",
    "50210002",
    "20201002",
    "50201014",
    "50201023",
    "50201003",
    "ERRCAD01",
    "ERRCAD02",
    "ERBDCD01",
    "ERBDCD02",
    "ERBDCD03",
    "ERBDCD04",
    "ERCACD01"

  ] as const;

/**
 * Type funcional Error
 */
export type FunctionalError = (typeof functionalErrors)[number];

/**
 * is functional error
 *
 * @param x any
 * @returns boolean
 */
export const isFunctionalError = (x: any): x is FunctionalError => functionalErrors.includes(x);

/**
 * Technical Errors Values
 */
const technicalErrors =
  [
    "50201002",
    "50301001",
    "40201003",
    "40201006",
    "50300001",
    "50000000",
    "40201005",
    "40201001",
    "40201004",
    "20300001",
    "50300006",
    "50201031",
    "30000000",
    "30000001",
    "50201035",
    "ERBBDD01",
    "ERMMPP01",

  ] as const;

/**
 * Technical Error Type
 */
export type TechnicalError = (typeof technicalErrors)[number];

/**
 * is technical error
 * @param x any
 * @returns boolean
 */
export const isTechnicalError = (x: any): x is TechnicalError => technicalErrors.includes(x);


