import { Autenticate } from "./../models/autenticate.model";
import { Operation } from "./../models/operation.model";
import { API_CALL_URL_SCA_MMPP } from "./../api/api.sca.constants";
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "@angular/common/http";
/**
 * Injectable
 */
/**
 * export class
 * SCAService
 */
export class SCAService {
    /**
     * constructor
     */
    constructor(apiService, http) {
        this.apiService = apiService;
        this.http = http;
        // variables
        this.sendOperationToAuth = new BehaviorSubject(new Operation());
        this.getOperationInAuth$ = this.sendOperationToAuth.asObservable();
    }
    /**
     * getOperation
     */
    getOperation(sortUrl) {
        const apiInstance = this.apiService.getApiInstance(API_CALL_URL_SCA_MMPP.GET_OPERATION);
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json').set('X-ClientId', 'scatar');
        apiInstance.params.sortUrl.value = sortUrl;
        const url = this.apiService.getApiUrl(apiInstance);
        return this.http
            .get(url, { headers: headers })
            .pipe(map(this.extractOperation), catchError(this.handleError));
    }
    /**
     * sendAuthentication
     * envia la contraseña/pin
     */
    sendAuthentication(password, shortUrl) {
        const apiInstance = this.apiService.getApiInstance(API_CALL_URL_SCA_MMPP.AUTHENTICATION);
        const body = {
            shortUrl,
            password,
        };
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json').set('X-ClientId', 'scatar');
        const url = this.apiService.getApiUrl(apiInstance);
        return this.http
            .post(url, body, { headers: headers })
            .pipe(map(this.extractResponse), catchError(this.handleError));
    }
    /**
     * sendOperationToAuthentication
     * envia los datos para autenticarlos
     */
    sendOperationToAuthentication(operation) {
        this.sendOperationToAuth.next(operation);
    }
    /**
     * getOperationToAuthentication
     * recoge los datos de la autenticacion
     */
    getOperationToAuthentication() {
        return this.getOperationInAuth$;
    }
    /**
     * extrae los datos de la oepracion
     */
    extractOperation(res) {
        return Operation.buildJson(res, Operation);
    }
    /**
     * obtiene respuesta de autenticacion
     */
    extractResponse(res) {
        return Autenticate.buildJson(res, Autenticate);
    }
    /**
     * Devuelve una excepcion, error
     */
    handleError(error) {
        return throwError(error);
    }
}
SCAService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SCAService_Factory() { return new SCAService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.HttpClient)); }, token: SCAService, providedIn: "root" });
