import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorModal, ErrorResponse, ErrorResponseBody } from '../models/error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  /**
   * Error
   */
  private readonly error = new BehaviorSubject<ErrorModal>({
    code: "",
    mainText: "",
    secondaryText: ""

  });
  /**
   * errorResponse
   */
  private errorResponse: ErrorResponseBody = {}

  /**
   * $error
   */
  readonly $error = this.error.asObservable();


  constructor(private readonly translateService: TranslateService) {
    translateService.onLangChange.subscribe(() => {
      this.add(this.errorResponse)
    })
  }
  /**
   * add
   * @param errorResponse errorResponse
   */
  public add(errorResponse: ErrorResponseBody) {
    this.errorResponse = errorResponse;
    const error: ErrorModal = {
      code: errorResponse.estado,
      mainText: errorResponse.descError ? this.getTextByLang(errorResponse.descError, true, this.translateService.currentLang) : "",
      secondaryText: errorResponse.descError ? this.getTextByLang(errorResponse.descError, false, this.translateService.currentLang) : "",
    }

    this.error.next(error);
  }

  /**
   * get text by lang
   * @param descError descError
   * @param isMain is main text or not
   * @param lang lang
   * @returns string
   */
  private getTextByLang(descError: { language: string, value: string }[] | undefined, isMain: boolean, lang: string = this.translateService.currentLang): string {
    if (!descError) {
      return "";
    }

    const item = descError.find(item => item.language === lang);

    if (!item) {
      return "";
    }

    if (isMain) {
      return item.value.split(".")[0];
    } else {
      const aux = item.value.split(".");
      aux.shift();
      return aux.join(".");
    }
  }
}
