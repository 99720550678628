/**
 * export const
 * API_CALL_URL_SCA_MMPP
 *
 */
export const API_CALL_URL_SCA_MMPP = {
  /**
   * GET_OPERATION
   * path: "scaemp/dataOperation/{sortUrl}",
   */
  GET_OPERATION: {
    path: "scaemp/dataOperation/{sortUrl}",
    params: {
      sortUrl: {
        key: "sortUrl",
        value: "",
      },
    },
  },
  /**
   * AUTHENTICATION
   * path: "scaemp/company-authentication/{sortUrl}/{password}",
   */
  AUTHENTICATION: {
    path: "scaemp/company-authentication",
    params: {},
  },
};
