import { Injectable } from '@angular/core';

@Injectable()
export class AppConstants {

  ROUTES_DIRECTORY = {
    AUTENTICATION: {
      path: '/SCA/company-authentication/'
    },
    MODAL_ERROR_SUCCESS: {
      path: '/SCA/modal-error/1'
    },
    MODAL_ERROR_NEW_TRY: {
      path: '/SCA/modal-error/2'
    },
    MODAL_ERROR_BLOCKED: {
      path: '/SCA/modal-error/4'
    },
    MODAL_ERROR_NOT_AUTENTICATED: {
      path: '/SCA/modal-error/5'
    },
    MODAL_ERROR_TIMEOUT: {
      path: '/SCA/modal-error/3'
    },
    MODAL_ERROR_PAGE_NOT_EXIST: {
      path: '/SCA/modal-error/6'
    },
    MODAL_ERROR_FUNCTIONAL_ERROR: {
      path: '/SCA/modal-error/7'
    },
    MODAL_ERROR_TECHNICAL_ERROR: {
      path: '/SCA/modal-error/8'
    },
    MODAL_ERROR_TECHNICAL_CUSTOM: {
      path: '/SCA/modal-error/9'
    }
  };

  LANGUAGE = {
    ES: 'ES',
    EN: 'EN',
    FR: 'FR',
    CA: 'CA',
    EU: 'EU',
    GL: 'GL'
  }

  SCRIPT_UTAG = {
    URL: 'https://tags.tiqcdn.com/utag/',
    UTAG_SYNC: '/utag.sync.js',
    UTAG_ASYNC: '/utag.js'
  };

  UTAG_DATA = {
    access_type : "publico",
    canal: "INT",
    page_lang: navigator.language,
    page_name: "/SCA_clave_pago/identificacion_juridicas",
    page_title: "SCA clave pago identificación jurídicas",
    page_type: "checkout",
    product_category: "Otras Operativas",
    product_subcategory: "SCA",
    entity: "Santander Empresas",
    site_id: "banco santander"
  };

  TEALIUM = {
    EVENT: {
      EV_SEND_ACTION: "evSendAction",
      EV_SHOW_VIEW: "evShowView",
    },
    ACTION: {
      CLICK: 'click',
      ERROR_LOGIN: 'error_login',
      SUCCESS_LOGIN: 'success_login',
    },
    CATEGORY: 'SCA_clave_SMS',
    LABEL: {
      REMEMBER_PASS: 'Pulsa enlace obtener PIN',
      NO_EXIST: 'La pagina no existe',
      SERVER_ERROR: 'Internal server error',
      BLOCKED_PASS: 'No ha sido posible identificar al usuario. Clave bloqueada',
      EXPIRED: 'Se ha superado el límite máximo de tiempo para identificarte',
      NEW_TRY: 'No ha sido posible identificar al usuario. Intentelo de nuevo',
      SUCCESS: 'Identificación realizada con éxito',
      HELP: 'Pulsa botón de ayuda PDF'
    },
    SUCCESS: {
      page_name: "/SCA_clave_pago/identificacion_juridicas_resumen",
      page_title: "SCA clave pago identificación jurídicas resumen",
      page_type: "thank_you",
    },
    ERROR: {
      page_name: "/SCA_clave_pago/error_url_juridicas",
      page_title: "SCA clave pago error url juridicas",
      page_type: "aviso"
    }
  };
}
