<div class="screen">
  <div class="title-language">
    <div class="title"><i class="icon-logo-santander"></i></div>
    <div class="language" tabindex="0"
      *ngIf="showComboLanguage" 
      (click)="showTooltipLanguage()" 
      (focusout)="showTooltipLanguage()">
      <div class="triangle-language"></div>
      <div class="triangle-text">{{languageSelected}}</div>
      <sca-tooltip-language *ngIf="showTooltip" (language)="setLanguage($event)" [languageSelected]="languageSelected">
      </sca-tooltip-language>
    </div>
  </div>
  <router-outlet></router-outlet> 
<div>
