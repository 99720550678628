import { JsonObject, JsonProperty, JsonConvert } from "json2typescript";

// @JsonObject("JsonData")
/**
 * export class
 * JsonData
 */
export class JsonData {
  /**
   * crea un nuevo Json
   */
  public static buildJson(dataJson: any, classReference: { new (): any }) {
    const jsonConvert = new JsonConvert();
    const object = jsonConvert.deserializeObject(dataJson, classReference);
    return object;
  }
  /**
   * crea una nueva lista de Json
   */
  public static buildJsonList(dataJson: any, classReference: { new (): any }) {
    const jsonConvert = new JsonConvert();
    const object = jsonConvert.deserializeArray(dataJson, classReference);
    return object;
  }
}
