import { JsonData } from "./common/json-data.model";
import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("statusTypes")
/**
 * export class Autenticate
 */
export class Autenticate extends JsonData {
	@JsonProperty("estado", String, true)
	estado?: string = undefined;
	@JsonProperty("descError", Object, true)
	descError?: { language: string, value: string }[] | null = undefined
}
/**
 * export namespace Autenticate
 */
// tslint:disable-next-line:no-namespace
export namespace Autenticate {
	/**
	 * exportacion de los tipos
	 * de estatus y
	 * de las constantes
	 */
	export type statusTypes = "OK" | "KO1" | "KO2" | "KO3" | "KO4" | "KO5";
	export const statusTypes = {
		OK: "OK" as statusTypes,
		KO1: "KO1" as statusTypes,
		KO2: "KO2" as statusTypes,
		KO3: "KO3" as statusTypes,
		KO4: "KO4" as statusTypes,
		KO5: "KO5" as statusTypes,
		KO6: "KO6" as statusTypes,
		KO7: "KO7" as statusTypes,
		ERRCAD02: "ERRCAD02" as statusTypes
	};
}
