import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ModalErrorComponent } from "./components/modal-error/modal-error.component";
import { AutenticationComponent } from "./components/autentication/autentication.component";
import { AppComponent } from "./app.component";

/**
 * const routes
 * rutas que son constantes
 */
const routes: Routes = [
  {
    path: ":sortUrl",
    pathMatch: "full",
    redirectTo: "/SCA/company-authentication/:sortUrl",
  },
  {
    path: "SCA/company-authentication/:sortUrl",
    component: AutenticationComponent,
    pathMatch: "full",
  },
  {
    path: "SCA/modal-error/:param",
    component: ModalErrorComponent,
  },
  {
    path: "**",
    redirectTo: "SCA/modal-error/6",
  },
];
/**
 * NgModule
 * imports and exports
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
/**
 * export class
 * AppRoutingModule
 */
export class AppRoutingModule {}
