import { AppConstants } from "./app.constants";
import { ConfigService } from "@ng-darwin/config";
import { TealiumUtagService } from "./core/services/utag.service";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";

@Component({
	selector: "sca-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
/**
 * export class
 * AppComponent
 */
export class AppComponent implements OnInit {
	/**
	 * variables
	 */
	showTooltip = false;
	languageSelected: string = this.appConstants.LANGUAGE.ES;
	showComboLanguage = false;
	/**
	 * constructor
	 */
	constructor(
		private readonly translate: TranslateService,
		private readonly router: Router,
		private readonly appConstants: AppConstants,
		private readonly tealium: TealiumUtagService,
		private readonly configService: ConfigService
	) {
		this.tealium.setConfig({
			account: String(this.configService.config.app.tealium_account),
			profile: String(this.configService.config.app.tealium_profile),
			environment: String(this.configService.config.app.tealium_environment),
		});
		translate.addLangs([
			appConstants.LANGUAGE.EN.toLocaleLowerCase(),
			appConstants.LANGUAGE.ES.toLocaleLowerCase(),
			appConstants.LANGUAGE.FR.toLocaleLowerCase(),
			appConstants.LANGUAGE.EU.toLocaleLowerCase(),
			appConstants.LANGUAGE.GL.toLocaleLowerCase(),
		]);
		translate.use(appConstants.LANGUAGE.ES.toLocaleLowerCase());
	}
	async ngOnInit() {
		this.checkShowCombo();
	}

	checkShowCombo() {
		this.router.events.forEach((event) => {
			if (event instanceof RoutesRecognized) {
				event.urlAfterRedirects.indexOf(
					this.appConstants.ROUTES_DIRECTORY.AUTENTICATION.path,
				) > -1
					? (this.showComboLanguage = true)
					: (this.showComboLanguage = false);
			}
		});
	}

	showTooltipLanguage() {
		this.showTooltip = !this.showTooltip;
	}

	setLanguage(languageSelected: string) {
		this.languageSelected = languageSelected;
		this.translate.use(this.languageSelected.toLocaleLowerCase());
	}
}
