<div *ngIf="param === 1" class="icono-modal"><i class="icon-success"></i></div>
<div *ngIf="param === 1" class="main-text">{{ 'MODAL_ERROR.MSG1' | translate}}</div>
<div *ngIf="param === 1" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG1' | translate}}</div>

<div *ngIf="showIconAdvertise()" class="icono-modal"><i class="icon-advertise"></i></div>
<div *ngIf="showMainText()" class="main-text">{{ 'MODAL_ERROR.MSG2' | translate}}</div>
<div *ngIf="param === 2" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG21' | translate}}</div>
<div *ngIf="param === 3" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG22' | translate}}</div>
<div *ngIf="param === 4" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG231' | translate}}</div>
<div *ngIf="param === 5" class="main-text">{{ 'MODAL_ERROR.MSG3' | translate}}</div>
<div *ngIf="param === 5" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG3' | translate}}</div>
<div *ngIf="param === 6" class="icono-modal"><i class="icon-unicornio"></i></div>
<div *ngIf="param === 6" class="main-text">{{ 'MODAL_ERROR.MSG4' | translate}}</div>
<div *ngIf="param === 6" class="secondary-text">{{ 'MODAL_ERROR.SCD_MSG4' | translate}}</div>

<!-- Technical -->
<div *ngIf="isTechnicalError()" class="icono-modal"><i class="icon-unicornio"></i></div>
<div *ngIf="isTechnicalError()" class="main-text">{{ "MODAL_ERROR.MSG4" | translate }}</div>
<div *ngIf="isTechnicalError()" class="secondary-text">{{ "MODAL_ERROR.MSG3" | translate}}</div>
<div *ngIf="isTechnicalError() && error.code && error.code !== '' " class="secondary-text">[ {{error.code}} ]</div>
<!-- Functional -->
<div *ngIf="isFunctionalError()" class="icono-modal"><i class="icon-advertise"></i></div>
<div *ngIf="isFunctionalError()" class="main-text">{{ error.mainText }}</div>
<div *ngIf="isFunctionalError()" class="secondary-text">{{error.secondaryText}}</div>

<div *ngIf="param === 9" class="icono-modal"><i class="icon-advertise"></i></div>
<div *ngIf="param === 9" class="main-text">{{ error.mainText }}</div>
<div *ngIf="param === 9" class="secondary-text">{{error.secondaryText}}</div>

<div class="button" *ngIf="param !== 6">
    <div (click)="closeModal()" class="text">{{ 'MODAL_ERROR.BUTTON_CLOSE' | translate }}</div>
</div>